<template>
    <svg
        fill="none"
        height="26"
        viewBox="0 0 26 26"
        width="26"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M20.1693 7.75595H5.64716V9.36952H20.1693V7.75595Z"
            fill="currentColor"
        />
        <path
            d="M20.1693 12.193H5.64716V13.8066H20.1693V12.193Z"
            fill="currentColor"
        />
        <path
            d="M13.3116 16.6304H5.64716V18.2439H13.3116V16.6304Z"
            fill="currentColor"
        />
        <path
            d="M24.2031 2.51172H1.61316V23.4881H24.2031V2.51172ZM22.5895 21.8745H3.22673V4.12529H22.5895V21.8745Z"
            fill="currentColor"
        />
    </svg>
</template>
