<template>
    <svg
        fill="none"
        height="26"
        viewBox="0 0 26 26"
        width="26"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M2.0166 23.8912H23.7998V2.10803H2.0166V23.8912ZM22.1862 17.6458L16.5704 12.0299L18.9201 9.68015L22.1862 12.9459V17.6458ZM3.63017 3.7216H22.1862V10.664L18.9203 7.39801L15.4294 10.889L10.387 5.84657L3.63017 12.6034V3.7216ZM3.63017 14.8855L10.387 8.12866L22.1862 19.9279V22.2776H3.63017V14.8855Z"
            fill="currentColor"
        />
    </svg>
</template>
